import axios from 'axios'
import store from '@/store'
import { getToken, getParkId } from '@/utils/auth'
import { ElMessage } from 'element-plus'
import router from '@/router'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API
})

// request 拦截
service.interceptors.request.use(
  (config) => {
    // 可以在请求头设置token
    // 可以处理请求体格式
    if (store.getters.token) {
      // 判断是高德地图api 就不加了
      if (config.url.indexOf('https://restapi.amap.com/') !== 0) {
        config.headers['satoken'] = getToken()
        // config.headers['parkId'] = getParkId()
      }
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// response 拦截
service.interceptors.response.use(
  (response) => {
    const res = response.data
    // 可以处理各种状态码
    // ...
    if (res.code === 10021) {
      ElMessage({
        type: 'error',
        message: res.msg
      })
      store.commit('RESET_STATE')
      window.location.reload()
      // router.push({ path: '/login' })
    }

    return res
  },
  (error) => {
    if (error.response.data.code === '00001') {
      store.commit('RESET_STATE')
      window.location.reload()
    } else {
      ElMessage({
        type: 'error',
        message: '服务异常，请稍后再试!'
      })
      return Promise.reject(error)
    }
  }
)

export default service
