// 按钮权限指令
import store from '@/store'
export default {
  install(app) {
    app.directive('action', {
      beforeMount(el, binding) {
        const actionName = binding.arg
        const roles = store.getters.roles

        if (!roles.length || roles.indexOf(actionName) === -1) {
          ;(el.parentNode && el.parentNode.removeChild(el)) || (el.style.display = 'none')
        }
      }
    })
  }
}
