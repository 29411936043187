import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import directive from '@/utils/authorityInstruction'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'dayjs/locale/zh-cn'
import '@/utils/rem'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import 'element-plus/dist/index.css'
import './styles/element/index.css'
import './styles/init.css'
import './permission' // 权限控制

const app = createApp(App)
app.use(directive).use(store).use(router).use(ElementPlus,{ locale: zhCn})

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.mount('#app')
