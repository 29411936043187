/*
 * @Author: 彭璟
 * @Date: 2022-07-07 14:37:13
 * @LastEditors: 彭璟
 * @LastEditTime: 2022-12-27 15:47:33
 * @Description: File description
 */
const title = '智慧5G企业服务云平台'

export default function getPageTitle(pageTitle) {
  if (pageTitle) {
    return `${pageTitle} - ${title}`
  }
  return `${title}`
}
