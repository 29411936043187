import { createStore } from 'vuex'
import permission from './modules/async-router'
import user from './modules/user'
import getters from './getters'

export default createStore({
  modules: {
    permission,
    user
  },
  getters,
  state: {
    tabIndex: '0'
  },
  mutations: {
    SET_TAB_INDEX: (state, tabIndex) => {
      state.tabIndex = tabIndex
    }
  },
  actions: {}
})
