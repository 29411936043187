import { createRouter, createWebHashHistory } from 'vue-router'
import ModuleLayout from '@/layout/ModuleLayout.vue'

const router = createRouter({
  history: createWebHashHistory(), // hash模式：createWebHashHistory，history模式：createWebHistory
  routes: [
    {
      path: '/',
      // component: Layout,
      redirect: '/workbench/workbench-home'
    },
    {
      path: '/login',
      component: () => import('../views/login-page.vue')
      // redirect: '/login-page',
    },
    {
      path: '/workbench', // 工作台
      component: () => import('../layout/ModuleLayout.vue'),
      redirect: '/workbench/workbench-home', // 重定向工作台首页
      meta: {
        // title: '工作台',
        children: [
          {
            path: '/workbench/workbench-home',
            title: '工作台'
          },
          {
            path: '/workbench/user-info',
            title: '个人资料'
          },
          {
            path: '/workbench/agency-center',
            title: '代办中心'
          }
        ]
      },
      children: [
        {
          path: 'workbench-home',
          name: 'workbench-home',
          component: () => import('@/views/workbench/workbench-home.vue'),
          meta: {
            // title: '工作台'
          }
        },
        {
          path: 'user-info',
          name: 'user-info',
          component: () => import('@/views/user-page/user-info.vue'),
          meta: {
            title: '个人资料'
          }
        },
        {
          path: 'agency-center',
          name: 'agency-center',
          component: () => import('@/views/process-platform/agency-center.vue'),
          meta: {
            title: '个人资料'
          }
        }
      ]
    },
    {
      path: '/open-service-platform',
      component: () => import('../layout/ModuleLayout.vue'),
      redirect: '/open-service-platform/service-platform',
      meta: {
        title: '服务开放平台',
        children: [
          {
            path: '/open-service-platform/service-platform',
            title: '服务开放平台'
          }
        ]
      },
      children: [
        {
          path: 'service-platform',
          name: 'service-platform',
          component: () => import('@/views/open-service-platform/service-platform.vue'),
          meta: {
            title: '服务开放平台'
          }
        }
      ]
    },
    {
      path: '/application-center',
      component: () => import('../layout/ModuleLayout.vue'),
      redirect: '/application-center/application-center',
      meta: {
        title: '应用中心',
        children: [
          {
            path: '/application-center/application-center',
            title: '应用中心'
          },
          {
            path: '/application-center/application-center-details',
            title: '应用详情'
          }
        ]
      },
      children: [
        {
          path: 'application-center',
          name: 'application-center',
          component: () => import('@/views/application-center/application-center.vue'),
          meta: {
            title: '应用中心'
          }
        },
        {
          path: 'application-center-details',
          name: 'application-center-details',
          component: () => import('@/views/application-center/application-center-details.vue'),
          meta: {
            title: '应用详情'
          }
        }
      ]
    },
    {
      path: '/process-platform',
      component: () => import('../layout/ModuleLayout.vue'),
      redirect: '/process-platform/process-platform',
      meta: {
        title: '流程平台',
        children: [
          {
            path: '/process-platform/process-platform',
            title: '流程平台'
          },
          {
            path: '/process-platform/office-module-page',
            title: '公办模块'
          },
          {
            path: '/process-platform/human-module-page',
            title: '人资模块'
          },
          {
            path: '/process-platform/project-management-page',
            title: '项目管理'
          },
          {
            path: '/process-platform/my-launch-see',
            title: '流程详情'
          }
        ]
      },
      children: [
        {
          path: 'process-platform',
          name: 'process-platform',
          component: () => import('@/views/process-platform/process-platform.vue'),
          meta: {
            title: '流程平台'
          }
        },
        {
          path: 'office-module-page',
          name: 'office-module-page',
          component: () => import('@/views/process-platform/components/office-module-page.vue'),
          meta: {
            title: '公办模块'
          }
        },
        {
          path: 'human-module-page',
          name: 'human-module-page',
          component: () => import('@/views/process-platform/components/human-module-page.vue'),
          meta: {
            title: '人资模块'
          }
        },
        {
          path: 'project-management-page',
          name: 'project-management-page',
          component: () => import('@/views/process-platform/components/project-management-page.vue'),
          meta: {
            title: '项目管理'
          }
        },
        {
          path: 'my-launch-see',
          name: 'my-launch-see',
          component: () => import('@/views/process-platform/components/my-launch-see.vue'),
          meta: {
            title: '流程详情'
          }
        }
      ]
    },
    {
      path: '/news-list',
      component: () => import('../layout/ModuleLayout.vue'),
      meta: {
        title: '公告列表'
      },
      children: [
        {
          path: '/news-list',
          name: 'news-list',
          component: () => import('@/views/workbench/news-list.vue'),
          meta: {
            title: '公告列表'
          }
        }
      ]
    },
    {
      path: '/news-detail',
      component: () => import('../layout/ModuleLayout.vue'),
      meta: {
        title: '新闻详情'
      },
      children: [
        {
          path: '/news-detail',
          name: 'news-detail',
          component: () => import('@/views/workbench/news-detail.vue'),
          meta: {
            title: '新闻详情'
          }
        }
      ]
    },
    {
      path: '/setup-menu',
      component: () => import('../layout/ModuleLayout.vue'),
      meta: {
        title: '设置菜单'
      },
      children: [
        {
          path: '/setup-menu',
          name: 'setup-menu',
          component: () => import('@/views/workbench/setup-menu.vue'),
          meta: {
            title: '设置菜单'
          }
        }
      ]
    },
    {
      path: '/task-details',
      component: () => import('../layout/ModuleLayout.vue'),
      meta: {
        title: '任务详情'
      },
      children: [
        {
          path: '/task-details',
          name: 'task-details',
          component: () => import('@/views/schedule-management/task-details.vue'),
          meta: {
            title: '任务详情'
          }
        }
      ]
    },
    {
      path: '/notification-list',
      component: () => import('../layout/ModuleLayout.vue'),
      meta: {
        title: '通知中心'
      },
      children: [
        {
          path: '/notification-list',
          name: 'notification-list',
          component: () => import('@/views/workbench/notification-list.vue'),
          meta: {
            title: '通知中心'
          }
        }
      ]
    },
    {
      path: '/notification-details',
      component: () => import('../layout/ModuleLayout.vue'),
      meta: {
        title: '通知详情'
      },
      children: [
        {
          path: '/notification-details',
          name: 'notification-details',
          component: () => import('@/views/workbench/notification-details.vue'),
          meta: {
            title: '通知详情'
          }
        }
      ]
    },
    {
      path: '/schedule-management',
      component: () => import('../layout/ModuleLayout.vue'),
      meta: {
        title: '日程管理'
      },
      children: [
        {
          path: '/schedule-management',
          name: 'schedule-management',
          component: () => import('@/views/schedule-management/index.vue'),
          meta: {
            title: '日程管理'
          }
        }
      ]
    },
    {
      path: '/task-search',
      component: () => import('../layout/ModuleLayout.vue'),
      meta: {
        title: '日程管理'
      },
      children: [
        {
          path: '/task-search',
          name: 'task-search',
          component: () => import('@/views/schedule-management/task-search.vue'),
          meta: {
            title: '任务搜索'
          }
        }
      ]
    }
  ]
})

export default router
