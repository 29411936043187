<template>
  <div class="module-layout">
    <top-menu />
    <div class="main">
      <div class="left-menu">
        <slider-menu />
      </div>
      <div class="right-menu">
        <div class="router-view" :style="{ width: width }">
          <el-scrollbar>
            <router-view :class="$route?.path?.split(`/`)?.[1]" />
          </el-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TopMenu from './components/topMenu.vue'
  import SliderMenu from './components/sliderMenu.vue'
  import { ref } from 'vue'
  export default {
    components: {
      TopMenu,
      SliderMenu
    },
    setup() {
      const width = ref('')
      const getWidth = () => {
        let clientWidth = document.documentElement.clientWidth
        let maxScale = clientWidth / 1920
        if (maxScale === 1) {
          width.value = 1920 - 104 + 'px'
          return
        } else {
          width.value = clientWidth - 104 + 'px'
        }
      }
      getWidth()

      window.onresize = function () {
        getWidth()
      }
      return {
        width,
        getWidth
      }
    }
  }
</script>

<style lang="scss" scoped>
  .module-layout {
    background-image: url('~@/assets/home/bg.png');
    background-size: 100% 100%;
  }
  .main {
    width: 100%;
    height: calc(100vh - 76px);
    display: flex;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;

    .left-menu {
      width: 135px;
      height: 100vh;
      .slider {
        width: 80px;
        height: 100vh;
      }
    }

    .right-menu {
      flex: 1;
      display: flex;
      flex-direction: column;
      .router-view {
        height: 100%;
        overflow-y: auto;
        overflow-x: auto;
      }
    }
  }
</style>
