import { ElMessage } from 'element-plus'
import { login, logout, getUserinfo, updatePwd } from '@/api/basic/user'
import { useRouter } from 'vue-router'

import store from '@/store'
import {
  setToken,
  setParkId,
  setUserId,
  getToken,
  getParkId,
  getUserId,
  removeToken,
  removeParkId,
  removeUserId
} from '@/utils/auth'
import { filterParams } from '@/utils/helper'

const getDefaultState = () => {
  return {
    token: getToken() || null,
    parkId: getParkId() || null,
    userId: getUserId() || null,
    userInfo: JSON.parse(localStorage.getItem('user_info') || '{}'),
    userMenu: []
  }
}

const state = getDefaultState()
const router = useRouter()

const mutations = {
  RESET_STATE: (state) => {
    localStorage.setItem('user_info', false)
    localStorage.removeItem('ip')
    removeToken()
    removeParkId()
    removeUserId()
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_PARK_ID: (state, parkId) => {
    state.parkId = parkId
  },
  SET_USER_ID: (state, userId) => {
    state.userId = userId
  },
  SET_USER_INFO: (state, info) => {
    // const userInfo = {
    //   id: '', // id
    //   username: '', // 用户名
    //   realName: '', // 真实姓名
    //   headUrl: '', // 头像
    //   gender: '', // 性别
    //   email: '', // 邮箱
    //   mobile: '', // 手机号
    //   deptId: '', // 部门id
    //   deptName: '', // 部门名称
    //   workNumber: '', // 工号
    //   status: '' // 用户状态
    // }
    // 过滤多余参数
    // filterParams(userInfo, info)
    state.userInfo = info
    localStorage.setItem('user_info', JSON.stringify(state.userInfo))
  },
  SET_USER_MENU: (state, menuList) => {
    state.userMenu = menuList
  }
}
const actions = {
  // 用户登录
  Login({ commit }, formData) {
    return new Promise((resolve, reject) => {
      login(formData)
        .then((res) => {
          if (res.code == 0) {
            const { satoken, id } = res.data
            commit('SET_TOKEN', satoken)
            setToken(satoken)
            setUserId(id)
            ElMessage({
              type: 'success',
              message: '登录成功'
            })
            resolve()
          } else {
            ElMessage({
              type: 'error',
              message: res.msg
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  Logout({ commit }) {
    return new Promise((resolve) => {
      logout().then(() => {
        commit('RESET_STATE')
        resolve()
      })
    })
  },
  GetUserInfo({ commit }) {
    return new Promise((resolve) => {
      getUserinfo().then((res) => {
        if (res.code == 0) {
          commit('SET_USER_INFO', res.data)
          resolve(res.data)
        }
      })
    })
  },
  UpdatePwd({ commit }, user) {
    return new Promise((resolve) => {
      updatePwd(user).then((res) => {
        resolve(res)
      })
    })
  }
}

export default {
  state,
  mutations,
  actions
}
