const baseSize = 16
// 设置 rem 函数
function setRem() {
  // 当前页面宽度相对于 1920 宽的缩放比例，可根据自己需要修改。
  let maxScale = document.documentElement.clientWidth / 1920
  let minScale = document.documentElement.clientWidth / 1380
  let scale

  if (maxScale >= 1) {
    scale = 1
  }
  if (maxScale < 1 && minScale > 1) {
    scale = maxScale
  }
  if (minScale <= 1) {
    scale = 1380 / 1920
  }

  scale = scale >= 1 ? 1 : scale
  // 设置页面根节点字体大小
  document.documentElement.style.fontSize = baseSize * Math.min(scale, 2) + 'px'
}
// 初始化
setRem()
// 改变窗口大小时重新设置 rem
window.onresize = function () {
  setRem()
}
