import Cookies from 'js-cookie'

const TokenName = 'shuzihua_token'
const ParkIdName = 'shuzihua_park_id'
const UserIdName = 'shuzihua_user_id'

export function getToken() {
  Cookies.get('token')
  return Cookies.get(TokenName)
}

export function setToken(token) {
  return Cookies.set(TokenName, token)
}

export function removeToken() {
  Cookies.remove('token')
  return Cookies.remove(TokenName)
}

export function getParkId() {
  return Cookies.get(ParkIdName)
}

export function setParkId(parkId) {
  return Cookies.set(ParkIdName, parkId)
}

export function removeParkId() {
  return Cookies.remove(ParkIdName)
}

export function getUserId() {
  return Cookies.get(UserIdName)
}

export function setUserId(userId) {
  return Cookies.set(UserIdName, userId)
}

export function removeUserId() {
  return Cookies.remove(UserIdName)
}

// 流程平台固定参数
export function fixedParams() {
  return {
    operator: getUserId(), // 操作人
    systemSn: 'digital'
  }
}
