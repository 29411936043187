<template>
  <div class="slider-menu">
    <div class="content">
      <div
        :class="[tabIndex === index ? 'block_' : 'block']"
        v-for="(item, index) in navigationList"
        :key="index"
        @click="navigationClick(item)"
      >
        <!-- <el-tooltip class="box-item" effect="dark" :content="item.title" placement="right"> -->
        <!-- </el-tooltip> -->
        <img :src="item.icon" />
        <p>{{ item.title }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
  import router from '@/router'
  import { reactive, ref, watch } from 'vue'
  import store from '@/store'

  let tabIndex = ref(0)
  const navigationList = reactive([
    {
      title: '工作台',
      icon: require('../../assets/home/menu-logo01.png'),
      path: '/workbench/workbench-home',
      id: 0
    },
    // { icon: require('../../assets/navigation/application_center.png'), path: '/open-service-platform/service-platform', id: 1 },
    {
      title: '应用中心',
      icon: require('../../assets/home/menu-logo02.png'),
      path: '/application-center/application-center',
      id: 1
    }
    // {
    //   title: '流程平台',
    //   icon: require('../../assets/navigation/process_platform.png'),
    //   path: '/process-platform/process-platform',
    //   id: 2
    // }
  ])

  const getDefaultRouter = () => {
    const routerUrl = router.currentRoute.value.fullPath
    switch (routerUrl) {
      case '/workbench/workbench-home':
        tabIndex.value = 0
        break
      case '/application-center/application-center':
        tabIndex.value = 1
        break
      case '/process-platform/process-platform':
        tabIndex.value = 2
        break
      default:
        tabIndex.value = 0
        break
    }
  }
  getDefaultRouter()
  const navigationClick = (item) => {
    tabIndex.value = item.id
    store.commit('SET_TAB_INDEX', tabIndex.value)
    router.push(item.path)
    // console.log(this.$route.path)
  }
</script>

<style lang="scss" scoped>
  .slider-menu {
    width: 135px;
    height: 100vh;
    // min-height: 1315px;
    display: flex;
    flex-direction: column;
    align-items: center;
    //position: fixed;
    //top: 0;
    //left: 0;
    //z-index: 99999;
    .content {
      padding-top: 33px;
      .logo{
        width: 56px;
        height: 56px;
        display: block;
        margin: 25px auto;
      }
      .block {
        width: 135px;
        height: 143px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
          width: 60px;
          height: 60px;
          margin-bottom: 7px;
        }
        p {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #fff;
          line-height: 20px;
        }
      }

      .block_ {
        width: 135px;
        height: 143px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-image: url('~@/assets/home/menu-bg.png');
        background-size: 100% 100%;
        img {
          width: 60px;
          height: 60px;
          margin-bottom: 7px;
        }
        p {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #0069FF;
          line-height: 20px;
        }
      }
    }
  }
</style>
