import request from '@/utils/request'

// 我的通知 - 任务通知
export function getNotice(data) {
  return request({
    baseURL: process.env.VUE_APP_BASE_NOTICE,
    url: '/notice/mynotice/page',
    method: 'post',
    data
  })
}

// 更换通知状态 - 任务通知
export function updateStatus(data) {
  return request({
    baseURL: process.env.VUE_APP_BASE_NOTICE,
    url: '/notice/mynotice/updateStatus',
    method: 'post',
    data
  })
}

// 根据creator 获取用户信息
export function getUserInfoById(params) {
  return request({
    url: '/login/userinfoById',
    method: 'get',
    params
  })
}
