/**
 * 向后端请求用户的菜单，动态生成路由
 */

const permission = {
  state: {},
  mutations: {},
  actions: {}
}

export default permission
