const getters = {
  addRouters: (state) => state.permission.addRouters,
  configRouters: (state) => state.permission.menuRouters,
  token: (state) => state.user.token,
  parkId: (state) => state.user.parkId,
  userId: (state) => state.user.userId,
  userInfo: (state) => state.user.userInfo,
  roles: (state) => state.permission.routePermission,
  tabIndex: (state) => state.tabIndex
}

export default getters
