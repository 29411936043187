import router from './router'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import getPageTitle from '@/utils/get-page-title'
import store from './store'
import { getToken } from '@/utils/auth'
import versionTood from '@/libs/versionUpdate'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login']

router.beforeEach(async (to, from, next) => {
  console.log(to, 'to')
  // start progress bar
  NProgress.start()
  // 检测版本是否与服务器一致
  // versionTood.isNewVersion()
  // set page title
  document.title = getPageTitle(to.meta.title)
  const hasToken = getToken()
  console.log(hasToken, 'token')
  if (hasToken) {
    if (to.path === '/login') {
      next()
      NProgress.done()
    } else {
      next()

      // if (!store.getters.addRouters.length) {
      //   try {
      //     await store.dispatch('GenerateRoutes')
      //     store.getters.addRouters.forEach((item) => {
      //       router.addRoute(item)
      //     })
      //     next({ ...to, replace: true })
      //   } catch (error) {
      //     console.log(error)
      //   }
      // } else {
      // next()
      // }
    }
  } else {
    // 不排除后面会增加登登录需求，先保留
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next(`/login`)
      NProgress.done()
    }
  }
})
router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
