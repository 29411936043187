<template>
  <div class="top-menu">
    <div class="menu-left flex-row-center">
      <div class="logo-box flex-row-center">
        <img src="@/assets/home/logo.png" alt="" style="border-radius: 5px;" />
        <p>智慧5G企业服务云平台</p>
      </div>
      <!-- <div class="search">
        <img src="../../assets/navigation/top_search.png" />
        <input type="text" v-model="input" class="input" placeholder="请输入搜索内容" placeholder-class="placeholder" />
      </div> -->
    </div>
    <div class="menu-right">
      <!-- <div class="adress flex-row-center">
        <img src="@/assets/home/adress.png" alt="" />
        <span>{{ state.weatherInfo.city }}</span>
      </div> -->
      <p class="date">{{ dayValue }}</p>
      <!-- <div class="weather flex-row-center">
        <img :src="weatherIcon" alt="" />
        <span class="text">{{ state.weatherInfo.weather }}</span>
        <span class="num">{{ state.weatherInfo.temperature }}℃</span>
      </div> -->
      <el-dropdown class="dropdown_box">
        <div class="menu-icon">
          <img src="@/assets/home/remind_message.png" />
          <!-- <img src="../../assets/index/skin.png" /> -->
          <div class="redBackground" v-if="state.notificationTotal !=0">
            <span>{{ state.notificationTotal > 99 ? '99+' : state.notificationTotal }}</span>
          </div>
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item
              v-for="item in state.notificationList"
              :key="item.id"
              @click="jumpDetail(item.primaryKey, item.id)"
            >
              <div class="notification-box">
                <div class="notification-top">
                  <div class="notification-top-box">
                    <img src="@/assets/tasks-to-do.png" alt="" />
                    任务待办
                  </div>
                  <div class="date">{{ item.senderDate }}</div>
                </div>
                <div class="notification-bom">{{ item.title }}</div>
              </div>
            </el-dropdown-item>
            <el-dropdown-item @click="router.push('/notification-list')" style="justify-content: center">
              更多通知
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>

      <el-dropdown>
        <div class="dropdown">
          <img class="head-portrait" :src="state.userInfo.headUrl || require('@/assets/default-avatar.png')" />
          <div class="name">{{ state.userInfo.realName }}</div>
          <div class="down-select"></div>
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item icon="Edit" @click="router.push('/workbench/user-info')">编辑资料</el-dropdown-item>
            <el-dropdown-item icon="Unlock" @click="openPassword()">修改密码</el-dropdown-item>
            <el-dropdown-item icon="SwitchButton" @click="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>

    <!-- 修改密码 -->
    <el-dialog
      v-model="passwordVisible"
      title="修改密码"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="600px"
    >
      <el-form
        ref="passwordRef"
        label-position="top"
        :model="state.passwordForm"
        :rules="passwordRules"
        label-width="120px"
        status-icon
      >
        <el-form-item label="账户：">
          {{ state.userInfo.username }}
        </el-form-item>
        <el-form-item label="原密码：" prop="oldPwd">
          <el-input type="password" v-model="state.passwordForm.oldPwd" placeholder="请输入原密码" />
        </el-form-item>
        <div class="notes">
          <el-icon><WarningFilled /></el-icon>新密码必须包含数字和字母组合，密码长度8~16位字符
        </div>
        <el-form-item label="新密码：" prop="newPwd">
          <el-input type="password" v-model="state.passwordForm.newPwd" placeholder="请输入新密码" />
        </el-form-item>
        <el-form-item label="确定密码：" prop="newPwd2">
          <el-input type="password" v-model="state.passwordForm.newPwd2" placeholder="请再次输入新密码" />
        </el-form-item>
      </el-form>
      <template v-slot:footer>
        <el-button @click="reset" icon="RefreshLeft">重置</el-button>
        <el-button type="primary" @click="savePwd()">提交</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
  import store from '@/store'
  import { ref, reactive, watchEffect } from 'vue'
  import { useRouter } from 'vue-router'
  import { ElMessage } from 'element-plus'
  import { getNotice, updateStatus } from '@/api/notification'
  import { getIP, getWeather } from '@/api/basic/user'
  import dayjs from 'dayjs'
  const weatherIcon = ref('')
  // 获取当前城市编码
  const getIPAddress = async () => {
    const res = await getIP({
      key: 'd549eb75bf80596d83021b8c268fe232'
    })
    if (res.status === '1') {
      getWeatherInfo(res.adcode)
    }
  }

  // 获取天气
  const getWeatherInfo = async (city) => {
    const res = await getWeather({
      key: 'd549eb75bf80596d83021b8c268fe232',
      city: city
    })
    if (res.status === '1') {
      state.weatherInfo = res.lives[0]
      weatherIcon.value = _getIcon(res.lives[0].weather)
    }
  }
  const iconWeatherMap = {
    风: [
      '有风',
      '平静',
      '微风',
      '和风',
      '清风',
      '强风/劲风',
      '疾风',
      '大风',
      '烈风',
      '风暴',
      '狂爆风',
      '飓风',
      '热带风暴',
      '龙卷风'
    ],
    多云: ['少云', '晴间多云', '多云'],
    雪: ['雪', '阵雪', '小雪', '中雪', '大雪', '暴雪', '小雪-中雪', '中雪-大雪', '大雪-暴雪', '冷'],
    雾: ['浮尘', '扬沙', '沙尘暴', '强沙尘暴', '雾', '浓雾', '强浓雾', '轻雾', '大雾', '特强浓雾'],
    晴: ['晴', '热'],
    雨夹雪: ['雨雪天气', '雨夹雪', '阵雨夹雪'],
    雨: [
      '阵雨',
      '雷阵雨',
      '雷阵雨并伴有冰雹',
      '小雨',
      '中雨',
      '大雨',
      '暴雨',
      '大暴雨',
      '特大暴雨',
      '强阵雨',
      '强雷阵雨',
      '极端降雨',
      '毛毛雨/细雨',
      '雨',
      '小雨-中雨',
      '中雨-大雨',
      '大雨-暴雨',
      '暴雨-大暴雨',
      '大暴雨-特大暴雨',
      '冻雨'
    ],
    阴: ['阴', '霾', '中度霾', '重度霾', '严重霾', '未知']
  }

  function _getIcon(weather) {
    // 这个是icon的默认值
    let url = require('@/assets/weather/阴.png')

    for (const weatherKey in iconWeatherMap) {
      if (Object.hasOwnProperty.call(iconWeatherMap, weatherKey)) {
        const weatherNames = iconWeatherMap[weatherKey]
        const findWeatherItem = weatherNames.find((name) => weather === name)

        // 如果找了某一类的图标了，那重新赋值url
        if (findWeatherItem) {
          // 这里的weatherKey和icon的名字一一对应了
          url = require(`@/assets/weather/${weatherKey}.png`)
          // console.debug('@find weather key = ', weatherKey)
          break
        }
      }
    }
    return url
  }

  const checkNewPwd = (rule, value, callback) => {
    if (value === '') {
      callback(new Error('请填写新密码'))
    } else if (!/^(?=.*\d)(?=.*[a-z]).{8,16}$/.test(value)) {
      callback(new Error('密码必须包含字母和数字组合，密码长度8~16位字符!'))
    } else {
      callback()
    }
  }

  const checkNewPwd2 = (rule, value, callback) => {
    if (value === '') {
      callback(new Error('请再次填写新密码'))
    } else if (value !== state.passwordForm.newPwd) {
      callback(new Error('两次输入的密码不一样'))
    } else {
      callback()
    }
  }

  const router = useRouter()
  const input = ref('')
  const passwordVisible = ref(false)
  const dayValue = ref(dayjs().format('YYYY/MM/DD'))
  const state = reactive({
    notificationList: [],
    notificationTotal: 0,
    userInfo: store.state.user.userInfo || {},
    passwordForm: {},
    weatherInfo: {}
  })
  const passwordRef = ref()
  const passwordRules = reactive({
    oldPwd: [{ required: true, message: '请填写原密码', trigger: 'blur' }],
    newPwd: [
      { required: true, message: '请填写新密码', trigger: 'blur' },
      { validator: checkNewPwd, trigger: 'blur' }
    ],
    newPwd2: [
      { required: true, message: '请再次填写新密码', trigger: 'blur' },
      { validator: checkNewPwd2, trigger: 'blur' }
    ]
  })

  const getNoticeList = () => {
    getNotice({
      page: 1,
      limit: 9999,
      readStatus: 0
    }).then((res) => {
      if (res.code === 0) {
        let data = res.data.list
        data.splice(5)
        state.notificationList = data
        state.notificationTotal = res.data.total
      }
    })
  }

  const openPassword = () => {
    reset()
    state.passwordForm = { ...state.userInfo }
    passwordVisible.value = true
  }

  const reset = () => {
    passwordRef.value?.resetFields()
  }

  const savePwd = () => {
    passwordRef.value.validate((valid) => {
      if (valid) {
        store.dispatch('UpdatePwd', state.passwordForm).then((res) => {
          if (res.code == 0) {
            passwordVisible.value = false
            ElMessage({
              type: 'success',
              message: '修改密码成功！'
            })
          }
        })
      }
    })
  }

  const jumpDetail = async (key, id) => {
    let ids = [id]
    let params = {
      ids,
      readStatus: 1
    }
    const res = await updateStatus(params)
    if (res.code === 0) {
      router.push({
        name: 'notification-details',
        query: {
          id: key
        }
      })
    }
  }

  const logout = () => {
    store.dispatch('Logout').then(() => {
      router.push('/login')
    })
  }

  watchEffect(() => {
    console.log(store.state.user.userInfo.fixPhone)
  })

  getNoticeList()
  getIPAddress()
</script>

<style lang="scss" scoped>
  .dropdown .down-select {
    border-top-color: #fff !important;
  }
  .top-menu {
    width: 100%;
    height: 76px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // position: fixed;
    .title {
      font-size: 30px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #ffffff;
      line-height: 32px;
      background: linear-gradient(180deg, #ffffff 0%, #8db3d2 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-bottom: 9px;
      align-items: center;
    }
    .logo-box {
      margin-left: 44px;
      img {
        width: 44px;
        height: 44px;
        margin-right: 31px;
      }
      p {
        font-size: 22px;
        font-family: YouSheBiaoTiHei;
        color: #ffffff;
        line-height: 32px;
      }
    }
    .menu-right {
      display: flex;
      align-items: center;
      .adress {
        img {
          width: 28px;
          height: 28px;
          margin-right: 6px;
          margin-left: 28px;
        }
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        color: #ffffff;
        line-height: 25px;
        span {
          margin-right: 19px;
        }
      }
      .weather {
        img {
          width: 40px;
          height: 40px;
        }
        span {
          font-size: 18px;
          font-family: PingFangSC-Semibold, PingFang SC;
          color: #ffffff;
          line-height: 25px;
        }
        .text {
          margin-left: 11px;
          margin-right: 14px;
        }
        .num {
          margin-right: 62px;
        }
      }
      .date {
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        color: #ffffff;
        line-height: 25px;
        margin-right: 42px;
      }
    }
  }

  :deep(.notification-box) {
    border-bottom: 1px dotted #eaeaea;
    padding: 10px 0;

    .notification-top {
      display: flex;
      align-items: center;
      .notification-top-box {
        display: flex;
        align-items: center;
        img {
          width: 18px;
          height: 18px;
          margin-right: 5px;
        }
      }
      .date {
        margin-left: 40px;
        font-size: 10px;
        font-family: PingFangSC-Regular, PingFang SC;
      }
    }
    .notification-bom {
      width: 280px;
      margin-top: 12px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .input {
    width: 100%;
    background: #e6e6eb;
    height: 32px;
    margin-right: 20px;
    border: none;
    outline: none;
    // color: #A9ADB8;
    font-size: 13px;
  }
  .placeholder {
    height: 18px;
    font-size: 13px;
    font-family: PingFangHK-Regular, PingFangHK;
    font-weight: 400;
    color: #a9adb8;
    line-height: 18px;
  }
  .menu-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 7px;
    position: relative;
    img {
      width: 24px;
      height: 24px;
      // margin-right: 16px;
    }
    .redBackground {
      max-width: 60px;
      height: 18px;
      font-size: 12px;
      text-align: center;
      vertical-align: middle;
      color: #fff;
      position: absolute;
      top: -12px;
      left: 8px;
      background: #f53f3f;
      padding: 3px 6px;
      border-radius: 20px;
    }
  }
  .dropdown {
    display: flex;
    align-items: center;
    margin-left: 42px;
    .head-portrait {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }

    .name {
      height: 22px;
      font-size: 14px;
      font-family: PingFangHK-Medium, PingFangHK;
      font-weight: 500;
      color: #fff;
      line-height: 22px;
      margin-left: 16px;
      margin-right: 12px;
    }
    .down-select {
      width: 0;
      height: 0;
      border: 4.2px solid transparent;
      margin-right: 48px;
      margin-top: 7px;
      border-top-color: #333333;
    }
  }

  .notes {
    color: #aeaeae;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 12px;
    position: fixed;
    margin-left: 250px;
    font-family: PingFangSC-Regular, PingFang SC;
    i {
      margin-right: 5px;
    }
  }
</style>
