/*
 * @Author: 彭璟
 * @Date: 2022-09-13 11:10:55
 * @LastEditors: 彭璟
 * @LastEditTime: 2022-09-20 09:15:33
 * @Description: File description
 */
import request from '@/utils/request'

// 登录
export function login(data) {
  return request({
    url: '/login/username_pwd',
    method: 'post',
    data
  })
}

// 退出
export function logout(data) {
  return request({
    url: '/login/logout',
    method: 'post',
    data
  })
}

// 获取用户信息
export function getUserinfo(params) {
  return request({
    url: '/login/userinfo',
    method: 'get',
    params
  })
}

// 修改密码
export function updatePwd(data) {
  return request({
    url: '/login/updatePwd',
    method: 'post',
    data
  })
}

// 修改用户信息
export function updateUserInfo(data) {
  return request({
    url: '/login/updateUserInfo',
    method: 'post',
    data
  })
}

// 根据ip获取定位
export function getIP(params) {
  return request({
    url: 'https://restapi.amap.com/v3/ip',
    method: 'get',
    params
  })
}

// 获取天气
export function getWeather(params) {
  return request({
    url: 'https://restapi.amap.com/v3/weather/weatherInfo',
    method: 'get',
    params
  })
}
